.new_navbar {
  // margin-bottom: 1rem;
  height: auto;
  background-color: #e1eaf4;
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.new_navbar_login {
  height: auto;
  background-color: white;
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.nav_fontsize {
  font-size: 16px !important;
  align-items: start !important;
}

.icomplink {
  flex-direction: column;
}

//media queries

@media only screen and (min-width: 1200px) {
  .imagesizelogo {
    height: 100px !important;
  }
  .imagesizelogoicomp {
    height: 70px !important;
  }
}

@media only screen and (min-width: 1250px) {
  .imagesizelogo {
    height: 110px !important;
  }
  .imagesizelogoicomp {
    height: 75px !important;
  }
}

@media only screen and (min-width: 1330px) {
  .imagesizelogo {
    height: 120px !important;
  }
  .imagesizelogoicomp {
    height: 80px !important;
  }
}

@media only screen and (min-width: 1700px) {
  .imagesizelogo {
    height: 13rem !important;
  }
  .imagesizelogoicomp {
    height: 7rem !important;
  }
}

@media only screen and (max-width: 700px) {
  .imagesizelogo {
    height: 80px !important ;
  }
  .imagesizelogoicomp {
    height: 60px !important;
  }
}

@media only screen and (max-width: 323px) {
  .nav_fontsize {
    font-size: 8px !important;
    align-items: start !important;
  }
}

@media only screen and (max-width: 348px) {
  .nav_fontsize {
    font-size: 9px !important;
    align-items: start !important;
  }
}

@media only screen and (max-width: 374px) {
  .imagesizelogo {
    height: 100px !important;
    margin-left: -11px !important;
    margin-top: 10px;
  }
  .imagesizelogoicomp {
    height: 55px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 400px) {
  .nav_fontsize {
    font-size: 11px !important;
    align-items: start !important;
  }
}

@media only screen and (max-width: 453px) {
  .nav_fontsize {
    font-size: 12px !important;
    align-items: start !important;
  }
}

@media only screen and (max-width: 509px) {
  .nav_fontsize {
    font-size: 14px !important;
    align-items: start !important;
  }
}

@media all and (min-width: 375px) and (max-width: 451px) {
  .imagesizelogo {
    height: 8rem !important;
    margin-left: -15px !important;
    margin-top: 10px;
  }
  .imagesizelogoicomp {
    height: 3.5rem !important;
    margin-right: 0px !important;
  }
}

@media all and (min-width: 451px) and (max-width: 600px) {
  .imagesizelogo {
    height: 11rem !important;
    margin-left: -15px !important;
    margin-top: 10px;
  }
  .imagesizelogoicomp {
    height: 4rem !important;
    margin-right: 0px !important;
  }
}

@media all and (min-width: 601px) and (max-width: 767px) {
  .imagesizelogo {
    height: 11rem !important;
    margin-left: -15px !important;
    margin-top: 10px;
  }
  .imagesizelogoicomp {
    height: 4rem !important;
    margin-right: 0px !important;
  }
}

@media all and (min-width: 768px) and (max-width: 900px) {
  .imagesizelogo {
    height: 13rem !important;
    margin-left: -15px !important;
    margin-top: 10px;
  }
  .imagesizelogoicomp {
    height: 5rem !important;
    margin-right: 0px !important;
  }
}

@media all and (min-width: 901px) and (max-width: 1224px) {
  .imagesizelogo {
    height: 13rem !important;
    margin-left: 0px !important;
    margin-top: 10px;
  }
  .imagesizelogoicomp {
    height: 5rem !important;
    margin-right: 10px !important;
  }
}

@media all and (min-width: 1225px) and (max-width: 1440px) {
  .imagesizelogo {
    height: 13rem !important;
    margin-left: 0px !important;
    margin-top: 10px;
  }
  .imagesizelogoicomp {
    height: 5rem !important;
    margin-right: 30px !important;
  }
}

@media all and (min-width: 1441px) and (max-width: 1880px) {
  .imagesizelogo {
    height: 13rem !important;
    margin-left: 0px !important;
    margin-top: 20px;
  }
  .imagesizelogoicomp {
    height: 5rem !important;
    margin-right: 30px !important;
  }
}
