.tabStyle {
  display: flex;

  .alignCenterbtn {
    margin: auto;
    display: flex;
    //padding: 0px 266px;
    justify-content: center;
    align-items: center;
    gap: 40px;
    align-self: stretch;
    .alignbtn {
      height: 75px;

      .button {
        padding: 10px 30px;
        font-family: MontserratRegular;
        font-weight: bolder;
        font-size: 20px;
        border-radius: 20px;
        border: 1px solid #eb7460;
        color: #eb7460; /* Text color */
        background-color: transparent;
        font-weight: 700;
        font-style: normal;
        line-height: normal;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
          background-color: #f3a499;
          color: white;
        }

        &.active {
          background-color: #eb7460; /* Active background color */
          color: white; /* Active text color */
        }
      }
      .imgstyle {
        display: flex;
        justify-content: center;
        align-items: start;
        padding: 0px 20px;
        font-family: MontserratRegular;
        font-weight: bolder;
        font-size: 16px;
        border-radius: 20px;
        border: 1px #eb7460;
        color: #eb7460; /* Text color */
        background-color: transparent;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;

        img {
          height: 15px;
        }
      }
    }
  }
}

.container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: 100vh;
  gap: 2rem;
}

.leftSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cardContainer {
  display: flex;
  width: 580px;
  height: 436px;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 12px;
  background-color: white;

  &Header {
    background-color: #5a63a3;
    color: white;
    width: 580px;
    height: 100px;
    border-radius: 27px;
    display: flex;
    padding-inline: 25px;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
  }

  &Header1 {
    background-color: white;
    color: black;
    width: 580px;

    border-radius: 27px;
    padding-inline: 25px;
    display: flex;
    height: 100px;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
  }
  &Active {
    background-color: #5a63a3;
    color: white;
    width: 580px;
    height: 100px;
    border-radius: 27px;
    display: flex;
    padding-inline: 25px;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
  }

  .iconContainer {
    border-radius: 100px;
    padding: 17px;
    &Active {
      border: 2px solid #ffcb85; // Border color for active tab
    }

    &Inactive {
      border: 1px solid #5a63a3; // Border color for inactive tabs
    }
    &noArrowActive {
      border: 1px solid #5a63a3; // Border color for active tab
    }

    &noArrowInactive {
      border: 1px solid white; // Border color for inactive tabs
    }
    svg {
      width: 30px;
      height: 26px;
    }
  }

  .iconContainer1 {
    border-radius: 100px;
    padding: 17px;
    border: 1px solid #5a63a3; // Default border for inactive state
    // transition: border 0.3s;

    // When the tab is active, increase the border size
    &Active {
      border: 2px solid #ffcb85; // Border color for active tab
      transition: border 0.3s;

      // On hover, increase the border size to 5px
      &:hover {
        border: 5px solid #ffcb85; // Increase border size when active and hovered
      }
    }

    &Inactive {
      border: 1px solid #5a63a3; // Border color for inactive tabs
    }

    &noArrowActive {
      border: 1px solid #5a63a3; // Border color for active tab
    }

    &noArrowInactive {
      border: 1px solid white; // Border color for inactive tabs
    }

    svg {
      width: 30px;
      height: 26px;
    }
  }
  .arrow {
    fill: #5a63a3; // Default color for inactive tabs
  }

  .arrowActive {
    fill: #ffcb85; // Color for the active tab
  }

  .noarrow {
    fill: white;
  }
  .noarrowActive {
    fill: #5a63a3;
  }
}

.rightSection {
  border-radius: 12px;
  min-width: 580px;
  max-width: 580px;

  .infoBox {
    // Ensure the height adjusts based on the content
    border-radius: 12px;
    padding: 1rem 2rem 1rem 2rem;
    background-color: white;
    min-height: auto;
    max-height: none; // Remove any height constraints
    overflow: auto; // Add scroll if the content overflows
  }
  p {
    font-size: 16px;
    color: black;
    line-height: normal;
    font-weight: 400;
    font-style: normal;
  }

  hr {
    margin: 1rem 0;

    border-bottom: 1px solid black;
  }

  .subhead {
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: bolder;
    line-height: normal;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 0.5rem;

      a {
        color: #393939 !important;

        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
        text-decoration-line: underline !important;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.ancorstyle {
  color: #000 !important;

  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-decoration-line: underline !important;

  &:hover {
    text-decoration: underline;
  }
}

// @media (max-width: 1024px) {
//   .tabStyle {
//     .alignCenterbtn {
//       gap: 20px;

//       .alignbtn {
//         height: 60px;

//         .button {
//           font-size: 18px;
//           padding: 8px 20px;
//         }
//       }
//     }
//   }

//   .container {
//     flex-direction: column;
//     gap: 1rem;
//   }

//   .cardContainer {
//     width: 80%;
//     height: auto;
//     margin: auto;
//   }

//   .card {
//     &Header {
//       width: 100%;
//       height: 80px;
//       font-size: 20px;
//     }
//   }

//   .rightSection {
//     min-width: 90%;
//     max-width: 90%;
//     margin: auto;
//     .infoBox {
//       padding: 1rem;
//     }
//     p {
//       font-size: 16px;
//     }
//     .subhead {
//       font-size: 18px;
//     }
//   }
// }

// @media (max-width: 768px) {
//   .tabStyle {
//     .alignCenterbtn {
//       gap: 10px;

//       .alignbtn {
//         height: 50px;

//         .button {
//           font-size: 14px;
//           padding: 5px 10px;
//         }
//       }
//     }
//   }

//   .cardContainer {
//     width: 100%;
//   }

//   .card {
//     &Header {
//       height: 60px;
//       font-size: 18px;
//     }
//   }

//   .rightSection {
//     min-width: 100%;
//     .infoBox {
//       padding: 0.5rem;
//     }
//     p {
//       font-size: 14px;
//     }
//     .subhead {
//       font-size: 16px;
//     }
//   }
// }

// @media (max-width: 1194px) {
//   .tabStyle {
//     display: flex;

//     .alignCenterbtn {
//       margin: auto;
//       display: flex;
//       //padding: 0px 266px;
//       justify-content: center;
//       align-items: center;
//       gap: 40px;
//       align-self: stretch;
//       .alignbtn {
//         height: 75px;

//         .button {
//           padding: 10px 30px;
//           font-family: MontserratRegular;
//           font-weight: bolder;
//           font-size: 20px;
//           border-radius: 20px;
//           border: 1px solid #eb7460;
//           color: #eb7460; /* Text color */
//           background-color: transparent;
//           font-weight: 700;
//           font-style: normal;
//           line-height: normal;
//           cursor: pointer;
//           transition: background-color 0.3s, color 0.3s;

//           &:hover {
//             background-color: #f3a499;
//             color: white;
//           }

//           &.active {
//             background-color: #eb7460; /* Active background color */
//             color: white; /* Active text color */
//           }
//         }
//         .imgstyle {
//           display: flex;
//           justify-content: center;
//           align-items: start;
//           padding: 0px 20px;
//           font-family: MontserratRegular;
//           font-weight: bolder;
//           font-size: 16px;
//           border-radius: 20px;
//           border: 1px #eb7460;
//           color: #eb7460; /* Text color */
//           background-color: transparent;
//           cursor: pointer;
//           transition: background-color 0.3s, color 0.3s;

//           img {
//             height: 15px;
//           }
//         }
//       }
//     }
//   }

//   .container {
//     width: 100%;
//     height: auto;
//     display: flex;
//     justify-content: center;
//     margin-top: 20px;
//     height: 100vh;
//     gap: 2rem;
//   }

//   .leftSection {
//     display: flex;
//     flex-direction: column;
//     gap: 1rem;
//   }

//   .cardContainer {
//     display: flex;
//     width: 580px;
//     height: 436px;
//     flex-direction: column;
//     gap: 1rem;
//     cursor: pointer;
//   }

//   .card {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 1rem;
//     border-radius: 12px;
//     background-color: white;

//     &Header {
//       background-color: #5a63a3;
//       color: white;
//       width: 580px;
//       height: 100px;
//       border-radius: 27px;
//       display: flex;
//       padding-inline: 25px;
//       align-items: center;
//       font-size: 20px;
//       font-weight: 500;
//       line-height: normal;
//       font-style: normal;
//     }

//     &Header1 {
//       background-color: white;
//       color: black;
//       width: 580px;

//       border-radius: 27px;
//       padding-inline: 25px;
//       display: flex;
//       height: 100px;
//       align-items: center;
//       font-size: 20px;
//       font-weight: 500;
//       line-height: normal;
//       font-style: normal;
//     }
//     &Active {
//       background-color: #5a63a3;
//       color: white;
//       width: 580px;
//       height: 100px;
//       border-radius: 27px;
//       display: flex;
//       padding-inline: 25px;
//       align-items: center;
//       font-size: 20px;
//       font-weight: 500;
//       line-height: normal;
//       font-style: normal;
//     }

//     .iconContainer {
//       border-radius: 100px;
//       padding: 17px;
//       &Active {
//         border: 2px solid #ffcb85; // Border color for active tab
//       }

//       &Inactive {
//         border: 1px solid #5a63a3; // Border color for inactive tabs
//       }
//       &noArrowActive {
//         border: 1px solid #5a63a3; // Border color for active tab
//       }

//       &noArrowInactive {
//         border: 1px solid white; // Border color for inactive tabs
//       }
//       svg {
//         width: 30px;
//         height: 26px;
//       }
//     }

//     .iconContainer1 {
//       border-radius: 100px;
//       padding: 17px;
//       border: 1px solid #5a63a3; // Default border for inactive state
//       // transition: border 0.3s;

//       // When the tab is active, increase the border size
//       &Active {
//         border: 2px solid #ffcb85; // Border color for active tab
//         transition: border 0.3s;

//         // On hover, increase the border size to 5px
//         &:hover {
//           border: 5px solid #ffcb85; // Increase border size when active and hovered
//         }
//       }

//       &Inactive {
//         border: 1px solid #5a63a3; // Border color for inactive tabs
//       }

//       &noArrowActive {
//         border: 1px solid #5a63a3; // Border color for active tab
//       }

//       &noArrowInactive {
//         border: 1px solid white; // Border color for inactive tabs
//       }

//       svg {
//         width: 30px;
//         height: 26px;
//       }
//     }
//     .arrow {
//       fill: #5a63a3; // Default color for inactive tabs
//     }

//     .arrowActive {
//       fill: #ffcb85; // Color for the active tab
//     }

//     .noarrow {
//       fill: white;
//     }
//     .noarrowActive {
//       fill: #5a63a3;
//     }
//   }

//   .rightSection {
//     border-radius: 12px;
//     min-width: 580px;
//     max-width: 580px;

//     .infoBox {
//       // Ensure the height adjusts based on the content
//       border-radius: 12px;
//       padding: 1rem 2rem 1rem 2rem;
//       background-color: white;
//       min-height: auto;
//       max-height: none; // Remove any height constraints
//       overflow: auto; // Add scroll if the content overflows
//     }
//     p {
//       font-size: 16px;
//       color: black;
//       line-height: normal;
//       font-weight: 400;
//       font-style: normal;
//     }

//     hr {
//       margin: 1rem 0;

//       border-bottom: 1px solid black;
//     }

//     .subhead {
//       font-weight: bold;
//       color: #333;
//       margin-bottom: 1rem;
//       color: #000;
//       font-size: 20px;
//       font-style: normal;
//       font-weight: bolder;
//       line-height: normal;
//     }

//     ul {
//       list-style: none;
//       padding: 0;

//       li {
//         margin-bottom: 0.5rem;

//         a {
//           color: #393939 !important;

//           font-size: 16px !important;
//           font-style: normal !important;
//           font-weight: 400 !important;
//           line-height: normal !important;
//           text-decoration-line: underline !important;

//           &:hover {
//             text-decoration: underline;
//           }
//         }
//       }
//     }
//   }

//   .ancorstyle {
//     color: #000 !important;

//     font-size: 20px !important;
//     font-style: normal !important;
//     font-weight: 400 !important;
//     line-height: normal !important;
//     text-decoration-line: underline !important;

//     &:hover {
//       text-decoration: underline;
//     }
//   }
// }
@media (max-width: 900px) {
  .tabStyle {
    .alignCenterbtn {
      padding: 0px 20px;
      gap: 10px;
      .alignbtn {
        height: 50px;

        .button {
          font-size: 12px;
          padding: 10px 29px;
        }
      }
    }
  }

  .container {
    width: 100%;

    height: auto;
    margin: auto;
    flex-direction: column;
    gap: 1rem;
    margin-top: 40px;
    margin-left: 20px;
  }

  .cardContainer {
    display: flex;
    width: 430px;
    height: auto;
    flex-direction: column;
    gap: 1rem;
    margin: auto;
  }
  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 12px;
    background-color: white;

    &Header {
      background-color: #5a63a3;
      color: white;
      width: 430px;
      height: 80px;
      border-radius: 27px;
      display: flex;
      padding-inline: 25px;
      align-items: center;
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
      font-style: normal;
    }

    &Header1 {
      background-color: white;
      color: black;
      width: 430px;
      height: 80px;
      border-radius: 27px;
      padding-inline: 15px;
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      line-height: normal;
      font-style: normal;
    }
    &Active {
      background-color: #5a63a3;
      color: white;
      width: 430px;
      height: 80px;
      border-radius: 27px;
      display: flex;
      padding-inline: 15px;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      line-height: normal;
      font-style: normal;
    }

    .iconContainer {
      border-radius: 31.5px;
      padding: 6px;
      &Active {
        border: 1px solid #ffcb85; // Border color for active tab
      }

      &Inactive {
        border: 1px solid #5a63a3; // Border color for inactive tabs
      }
      &noArrowActive {
        border: 1px solid #5a63a3; // Border color for active tab
      }

      &noArrowInactive {
        border: 1px solid white; // Border color for inactive tabs
      }
      svg {
        width: 30px;
        height: 26px;
      }
    }
    .arrow {
      fill: #5a63a3; // Default color for inactive tabs
    }

    .arrowActive {
      fill: #ffcb85; // Color for the active tab
    }

    .noarrow {
      fill: white;
    }
    .noarrowActive {
      fill: #5a63a3;
    }
  }

  .rightSection {
    min-width: 100%;
    max-width: 100%;
  }

  .rightSection {
    border-radius: 12px;
    min-width: 430px;
    max-width: 430px;
    margin: auto;
    margin-bottom: 30px;

    .infoBox {
      // Ensure the height adjusts based on the content
      border-radius: 12px;
      padding: 1rem 1rem 1rem 1rem;
      background-color: white;
      min-height: auto;
      max-height: none; // Remove any height constraints
      overflow: auto; // Add scroll if the content overflows
    }
    p {
      font-size: 12px;
      color: black;
      line-height: normal;
      font-weight: 400;
      font-style: normal;
    }

    hr {
      margin: 1rem 0;

      border-bottom: 1px solid black;
    }

    .subhead {
      font-size: 1.2rem;
      font-weight: bold;
      color: #333;
      margin-bottom: 1rem;
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: bolder;
      line-height: normal;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 0.5rem;

        a {
          color: #393939 !important;

          font-size: 12px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
          text-decoration-line: underline !important;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .ancorstyle {
    color: #000 !important;

    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    text-decoration-line: underline !important;

    &:hover {
      text-decoration: underline;
    }
  }
  // .tabStyle {
  //   .alignCenterbtn {
  //     margin-top: 40px;
  //     padding: 0px 20px;
  //     gap: 10px;
  //     .alignbtn {
  //       height: 50px;

  //       .button {
  //         font-size: 12px;
  //         padding: 5px 10px;
  //       }
  //     }
  //   }
  // }

  // .container {
  //   flex-direction: column;
  //   gap: 1rem;
  // }

  // .cardContainer {
  //   display: flex;
  //   width: 300px;
  //   height: auto;
  //   flex-direction: column;
  //   gap: 1rem;
  //   margin: auto;
  // }
  // .card {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   padding: 1rem;
  //   border-radius: 12px;
  //   background-color: white;

  //   &Header {
  //     background-color: #5a63a3;
  //     color: white;
  //     width: 300px;
  //     height: 80px;
  //     border-radius: 27px;
  //     display: flex;
  //     padding-inline: 25px;
  //     align-items: center;
  //     font-size: 24px;
  //     font-weight: 500;
  //     line-height: normal;
  //     font-style: normal;
  //   }

  //   &Header1 {
  //     background-color: white;
  //     color: black;
  //     width: 300px;
  //     height: 60px;
  //     border-radius: 27px;
  //     padding-inline: 15px;
  //     display: flex;
  //     align-items: center;
  //     font-size: 12px;
  //     font-weight: 500;
  //     line-height: normal;
  //     font-style: normal;
  //   }
  //   &Active {
  //     background-color: #5a63a3;
  //     color: white;
  //     width: 300px;
  //     height: 60px;
  //     border-radius: 27px;
  //     display: flex;
  //     padding-inline: 15px;
  //     align-items: center;
  //     font-size: 12px;
  //     font-weight: 500;
  //     line-height: normal;
  //     font-style: normal;
  //   }

  //   .iconContainer {
  //     border-radius: 31.5px;
  //     padding: 6px;
  //     &Active {
  //       border: 1px solid #ffcb85; // Border color for active tab
  //     }

  //     &Inactive {
  //       border: 1px solid #5a63a3; // Border color for inactive tabs
  //     }
  //     &noArrowActive {
  //       border: 1px solid #5a63a3; // Border color for active tab
  //     }

  //     &noArrowInactive {
  //       border: 1px solid white; // Border color for inactive tabs
  //     }
  //     svg {
  //       width: 30px;
  //       height: 26px;
  //     }
  //   }
  //   .arrow {
  //     fill: #5a63a3; // Default color for inactive tabs
  //   }

  //   .arrowActive {
  //     fill: #ffcb85; // Color for the active tab
  //   }

  //   .noarrow {
  //     fill: white;
  //   }
  //   .noarrowActive {
  //     fill: #5a63a3;
  //   }
  // }

  // .rightSection {
  //   min-width: 100%;
  //   max-width: 100%;
  // }

  // .rightSection {
  //   border-radius: 12px;
  //   min-width: 300px;
  //   max-width: 300px;
  //   margin: auto;
  //   margin-bottom: 30px;

  //   .infoBox {
  //     // Ensure the height adjusts based on the content
  //     border-radius: 12px;
  //     padding: 1rem 1rem 1rem 1rem;
  //     background-color: white;
  //     min-height: 100px;
  //     max-height: 100px; // Remove any height constraints
  //     overflow: auto; // Add scroll if the content overflows
  //   }
  //   p {
  //     font-size: 12px;
  //     color: black;
  //     line-height: normal;
  //     font-weight: 400;
  //     font-style: normal;
  //   }

  //   hr {
  //     margin: 1rem 0;

  //     border-bottom: 1px solid black;
  //   }

  //   .subhead {
  //     font-size: 1.2rem;
  //     font-weight: bold;
  //     color: #333;
  //     margin-bottom: 1rem;
  //     color: #000;
  //     font-size: 12px;
  //     font-style: normal;
  //     font-weight: bolder;
  //     line-height: normal;
  //   }

  //   ul {
  //     list-style: none;
  //     padding: 0;

  //     li {
  //       margin-bottom: 0.5rem;

  //       a {
  //         color: #393939 !important;

  //         font-size: 12px !important;
  //         font-style: normal !important;
  //         font-weight: 400 !important;
  //         line-height: normal !important;
  //         text-decoration-line: underline !important;

  //         &:hover {
  //           text-decoration: underline;
  //         }
  //       }
  //     }
  //   }
  // }

  // .ancorstyle {
  //   color: #000 !important;

  //   font-size: 12px !important;
  //   font-style: normal !important;
  //   font-weight: 400 !important;
  //   line-height: normal !important;
  //   text-decoration-line: underline !important;

  //   &:hover {
  //     text-decoration: underline;
  //   }
  // }
}

// Media queries for mobile
@media (max-width: 600px) {
  .tabStyle {
    .alignCenterbtn {
      margin-top: 40px;
      padding: 0px 20px;
      gap: 10px;
      .alignbtn {
        height: 50px;

        .button {
          font-size: 12px;
          padding: 5px 10px;
        }
      }
    }
  }

  .container {
    width: 100%;

    height: auto;
    margin: auto;
    flex-direction: column;
    gap: 1rem;
    margin-top: 40px;
    margin-left: 20px;
  }

  .cardContainer {
    display: flex;
    width: 300px;
    height: auto;
    flex-direction: column;
    gap: 1rem;
    margin: auto;
  }
  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 12px;
    background-color: white;

    &Header {
      background-color: #5a63a3;
      color: white;
      width: 300px;
      height: 80px;
      border-radius: 27px;
      display: flex;
      padding-inline: 25px;
      align-items: center;
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
      font-style: normal;
    }

    &Header1 {
      background-color: white;
      color: black;
      width: 300px;
      height: 80px;
      border-radius: 27px;
      padding-inline: 15px;
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      line-height: normal;
      font-style: normal;
    }
    &Active {
      background-color: #5a63a3;
      color: white;
      width: 300px;
      height: 80px;
      border-radius: 27px;
      display: flex;
      padding-inline: 15px;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      line-height: normal;
      font-style: normal;
    }

    .iconContainer {
      border-radius: 31.5px;
      padding: 6px;
      &Active {
        border: 1px solid #ffcb85; // Border color for active tab
      }

      &Inactive {
        border: 1px solid #5a63a3; // Border color for inactive tabs
      }
      &noArrowActive {
        border: 1px solid #5a63a3; // Border color for active tab
      }

      &noArrowInactive {
        border: 1px solid white; // Border color for inactive tabs
      }
      svg {
        width: 30px;
        height: 26px;
      }
    }
    .arrow {
      fill: #5a63a3; // Default color for inactive tabs
    }

    .arrowActive {
      fill: #ffcb85; // Color for the active tab
    }

    .noarrow {
      fill: white;
    }
    .noarrowActive {
      fill: #5a63a3;
    }
  }

  .rightSection {
    min-width: 100%;
    max-width: 100%;
  }

  .rightSection {
    border-radius: 12px;
    min-width: 300px;
    max-width: 300px;
    margin: auto;
    margin-bottom: 30px;

    .infoBox {
      // Ensure the height adjusts based on the content
      border-radius: 12px;
      padding: 1rem 1rem 1rem 1rem;
      background-color: white;
      min-height: auto;
      max-height: none; // Remove any height constraints
      overflow: auto; // Add scroll if the content overflows
    }
    p {
      font-size: 12px;
      color: black;
      line-height: normal;
      font-weight: 400;
      font-style: normal;
    }

    hr {
      margin: 1rem 0;

      border-bottom: 1px solid black;
    }

    .subhead {
      font-size: 1.2rem;
      font-weight: bold;
      color: #333;
      margin-bottom: 1rem;
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: bolder;
      line-height: normal;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 0.5rem;

        a {
          color: #393939 !important;

          font-size: 12px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
          text-decoration-line: underline !important;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .ancorstyle {
    color: #000 !important;

    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    text-decoration-line: underline !important;

    &:hover {
      text-decoration: underline;
    }
  }
}
