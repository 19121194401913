.header_border {
  width: 100%;
  border: 2px solid #5a63a3;
}

.workers_header {
  width: 736px;
  height: 106px;
  margin: auto;
  margin-top: 20px;

  .heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: #eb7460;
  }

  .headcontent {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5%;
    color: #000000;
  }

  .sub_header {
    margin: 10px 0px 10px 0px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5%;
    padding: 10px 0 10px 0;
    color: #5a63a3;
  }

  .link_style {
    color: #393939 !important;
    font-size: 0.75rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    text-decoration-line: underline;
    cursor: pointer;
  }

  // Media Query for Tablet
  @media only screen and (max-width: 1024px) and (min-width: 600px) {
    width: 90%; // Adjust width for tablet
    height: auto; // Let height adjust dynamically

    .heading {
      font-size: 22px; // Slightly reduce font size
      line-height: 36px;
    }

    .headcontent {
      font-size: 13px; // Adjust content font size for tablet
      line-height: 22px;
    }

    .sub_header {
      font-size: 13px;
      line-height: 22px;
    }
  }

  // Media Query for Mobile
  @media only screen and (max-width: 600px) {
    padding: 10px;
    width: 100%; // Make the container take full width
    height: auto; // Let height adjust dynamically

    .heading {
      font-size: 20px; // Further reduce font size for mobile
      line-height: 32px;
    }

    .headcontent {
      font-size: 12px; // Smaller font for mobile
      line-height: 20px;
    }

    .sub_header {
      font-size: 12px; // Smaller sub-header font for mobile
      line-height: 20px;
    }
  }
}
