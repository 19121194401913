/* src/components/Loader/Loader.scss */
.loader_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1); /* Very white and faded background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top */
  pointer-events: none; /* Make background unresponsive */
}

.spinner {
  --size: 50px; /* Loader size */
  --first-block-clr: #eb7460;
  --second-block-clr: #e1eaf3;
  --clr: #111;
  width: var(--size);
  height: var(--size);
  position: relative;
}

.spinner::after,
.spinner::before {
  box-sizing: border-box;
  position: absolute;
  content: "";
  width: var(--size);
  height: var(--size);
  top: 50%;
  animation: up 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
  left: 50%;
  background: var(--first-block-clr);
}

.spinner::after {
  background: var(--second-block-clr);
  top: calc(50% - var(--size));
  left: calc(50% - var(--size));
  animation: down 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes down {
  0%,
  100% {
    transform: none;
  }
  25% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(100%) translateY(100%);
  }
  75% {
    transform: translateY(100%);
  }
}

@keyframes up {
  0%,
  100% {
    transform: none;
  }
  25% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-100%) translateY(-100%);
  }
  75% {
    transform: translateY(-100%);
  }
}
