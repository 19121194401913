.navcontent {
  grid-gap: 4rem;
  // margin-right: 120px;
  align-items: flex-end;
  justify-content: flex-end !important;
  width: 65%;
}

a {
  text-decoration: none;
  color: #1b9ef6;
  font-weight: bolder;
}

.dropdownmenu {
  padding-top: 2px;
  background-color: transparent;
  padding-bottom: 2px;
  border: none;
  margin-top: 1.5rem;

  .dropdownmenu_item {
    padding-inline: 0px;

    text-decoration: underline;
    position: relative; // Needed for the ::after element positioning

    &::after {
      content: "";
      position: absolute;
      right: 0; // Aligns the pseudo-element to the end of the item
      top: 50%;
      transform: translateY(-50%);
      width: 10px; // Adjust width as needed
      height: 2px; // Adjust height as needed
      background-color: transparent;
      transition: background-color 0.3s ease;
    }
    &:hover::after,
    &:focus::after {
      background-color: transparent !important;
    }
  }
}

.dropdownmenu_item:hover,
.dropdownmenu_item:focus {
  background-color: transparent !important;
}

.boldText {
  font-size: 16px;
  font-weight: 900;
}
//media queries

@media only screen and (max-width: 900px) {
  .navcontent {
    display: flex !important;
    // flex-direction: column !important;
    gap: 2rem;
    font-weight: 900;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 1166px) {
  .navcontent {
    font-size: 14px !important;
    gap: 3rem;
  }
}

@media only screen and (max-width: 1053px) {
  .navcontent {
    font-size: 13px !important;
    gap: 2.8rem;
  }
}

@media only screen and (max-width: 1000px) {
  .navcontent {
    font-size: 12px !important;
    gap: 2.8rem;
  }
}
